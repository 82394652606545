var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "py-4" }, [
    _c(
      "div",
      { staticStyle: { position: "relative" } },
      [
        _c("a-spin", { attrs: { spinning: _vm.spinning } }, [
          _vm.currentType == 1
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    {
                      staticClass: "actions flex jc-end mb-4",
                      staticStyle: { "min-height": "32px" },
                    },
                    [
                      _vm.isEditProduction
                        ? _c(
                            "a-button",
                            {
                              staticClass: "mr-3",
                              attrs: { type: "default" },
                              on: {
                                click: () => {
                                  _vm.isEditProduction = false
                                },
                              },
                            },
                            [_vm._v("取消")]
                          )
                        : _vm._e(),
                      _c(
                        "a-button",
                        {
                          directives: [
                            {
                              name: "has",
                              rawName: "v-has",
                              value: "production:edit",
                              expression: "'production:edit'",
                            },
                          ],
                          attrs: {
                            type: "primary",
                            icon: !_vm.isEditProduction ? "edit" : "save",
                          },
                          on: { click: _vm.editProduction },
                        },
                        [
                          _vm._v(
                            _vm._s(!_vm.isEditProduction ? "编辑" : "保存")
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.isEditProduction
                    ? _c(
                        "a-form-model",
                        {
                          ref: "form",
                          staticStyle: {
                            "overflow-y": "overlay",
                            height: "595px",
                          },
                        },
                        _vm._l(_vm.formData, function (item) {
                          return _c(
                            "a-form-model-item",
                            _vm._b(
                              { key: item.id, staticClass: "edit-form" },
                              "a-form-model-item",
                              _vm.layout,
                              false
                            ),
                            [
                              item.cardList[0]
                                ? [
                                    _c(
                                      "div",
                                      {
                                        class:
                                          item.cardList[0].dataList[0]
                                            .valueRequired == "YES"
                                            ? "required"
                                            : "no-required",
                                        attrs: { slot: "label" },
                                        slot: "label",
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(item.title) +
                                            "\n                  "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "-2px",
                                            },
                                          },
                                          [_vm._v(":")]
                                        ),
                                        item.cardList[0].dataList &&
                                        item.cardList.length > 1
                                          ? _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  ref:
                                                    item.cardList[0].id +
                                                    "TEXTAREA",
                                                  refInFor: true,
                                                  staticClass:
                                                    "description-div",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.getSelectCount(
                                                          item.cardList[0]
                                                        ) +
                                                          `${
                                                            item.description
                                                              ? item.description
                                                              : ""
                                                          }`
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        item.cardList[0].dataList &&
                                        item.cardList.length == 1
                                          ? _c("div", [
                                              item.cardList[0].type !=
                                              "TEXTAREA"
                                                ? _c(
                                                    "div",
                                                    {
                                                      class:
                                                        item.cardList[0]
                                                          .dataList[0]
                                                          .formValues.length >
                                                          1 &&
                                                        item.cardList[0].type !=
                                                          "TAG"
                                                          ? "description-div"
                                                          : "description-div-one",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.getSelectCount(
                                                              item.cardList[0]
                                                            ) +
                                                              `${
                                                                item.description
                                                                  ? item.description
                                                                  : ""
                                                              }`
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      ref:
                                                        item.cardList[0].id +
                                                        "TEXTAREA",
                                                      refInFor: true,
                                                      class: _vm.getClassName(
                                                        item.cardList[0],
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.getSelectCount(
                                                              item.cardList[0]
                                                            ) +
                                                              `${
                                                                item.description
                                                                  ? item.description
                                                                  : ""
                                                              }`
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                              _vm._l(item.cardList, function (wrapper) {
                                return [
                                  wrapper.dataList
                                    ? _c(
                                        "div",
                                        {
                                          key: wrapper.id,
                                          style:
                                            wrapper.type == "TEXTAREA"
                                              ? "margin-bottom:2px;display:flex;flex-wrap:wrap"
                                              : "display:flex;flex-wrap:wrap",
                                        },
                                        [
                                          wrapper.type == "INPUT"
                                            ? _c("a-input", {
                                                ref: "input" + wrapper.id,
                                                refInFor: true,
                                                staticStyle: {
                                                  "margin-bottom": "6px",
                                                  "margin-top": "4px",
                                                },
                                                attrs: {
                                                  placeholder:
                                                    wrapper.dataList[0]
                                                      .description,
                                                  disabled: _vm.isSucDis(item),
                                                },
                                                on: {
                                                  blur: (e) => {
                                                    _vm.inputBlur(e, wrapper)
                                                  },
                                                  change: (e) => {
                                                    _vm.inputChange(e, wrapper)
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    wrapper.dataList[0]
                                                      .formValues[0].value,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      wrapper.dataList[0]
                                                        .formValues[0],
                                                      "value",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "wrapper.dataList[0].formValues[0].value",
                                                },
                                              })
                                            : _vm._e(),
                                          wrapper.type == "INPUT_NUMBER"
                                            ? _c("a-input-number", {
                                                ref: "input" + wrapper.id,
                                                refInFor: true,
                                                staticStyle: {
                                                  "margin-bottom": "6px",
                                                  "margin-top": "4px",
                                                },
                                                attrs: {
                                                  min: 0,
                                                  placeholder:
                                                    wrapper.dataList[0]
                                                      .description,
                                                  disabled: _vm.isSucDis(item),
                                                },
                                                on: {
                                                  blur: (e) => {
                                                    _vm.inputNumberBlur(
                                                      e,
                                                      wrapper
                                                    )
                                                  },
                                                  change: (e) => {
                                                    _vm.inputNumberChange(
                                                      e,
                                                      wrapper
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    wrapper.dataList[0]
                                                      .formValues[0].value,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      wrapper.dataList[0]
                                                        .formValues[0],
                                                      "value",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "wrapper.dataList[0].formValues[0].value",
                                                },
                                              })
                                            : _vm._e(),
                                          wrapper.type == "DYNAMIC_FORM"
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "justify-content":
                                                      "space-between",
                                                    "align-items": "center",
                                                    width: "580px",
                                                    "margin-top": "-6px",
                                                  },
                                                },
                                                [
                                                  _vm._l(
                                                    wrapper.dataList,
                                                    function (data) {
                                                      return [
                                                        _c(
                                                          "a-form-model-item",
                                                          _vm._b(
                                                            {
                                                              key: data.id,
                                                              staticClass:
                                                                "form-select",
                                                            },
                                                            "a-form-model-item",
                                                            _vm.inlineLayout,
                                                            false
                                                          ),
                                                          [
                                                            data.value
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    attrs: {
                                                                      slot: "label",
                                                                    },
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                          " +
                                                                        _vm._s(
                                                                          data.value
                                                                        ) +
                                                                        "\n                          "
                                                                    ),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "margin-left":
                                                                              "-2px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          ":"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            data.type != "INPUT"
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "padding-top":
                                                                          "6px",
                                                                      },
                                                                  },
                                                                  _vm._l(
                                                                    data.formValues,
                                                                    function (
                                                                      formValue,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "div",
                                                                        {
                                                                          key: index,
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "a-select",
                                                                            {
                                                                              ref:
                                                                                "select" +
                                                                                data.id +
                                                                                index,
                                                                              refInFor: true,
                                                                              style:
                                                                                wrapper
                                                                                  .dataList
                                                                                  .length >
                                                                                  1 &&
                                                                                !data.value
                                                                                  ? "width:243px"
                                                                                  : wrapper
                                                                                      .dataList
                                                                                      .length >
                                                                                      1 &&
                                                                                    data.value
                                                                                  ? "width:173px"
                                                                                  : data.value
                                                                                  ? "width:430px;"
                                                                                  : "width:500px",
                                                                              attrs:
                                                                                {
                                                                                  allowClear:
                                                                                    "",
                                                                                  placeholder:
                                                                                    wrapper
                                                                                      .dataList[0]
                                                                                      .description,
                                                                                  disabled:
                                                                                    _vm.isSucDis(
                                                                                      item
                                                                                    ),
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  (
                                                                                    e
                                                                                  ) => {
                                                                                    _vm.formSelectChange(
                                                                                      e,
                                                                                      data,
                                                                                      wrapper.dataList,
                                                                                      index
                                                                                    )
                                                                                  },
                                                                              },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    formValue.value,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        formValue,
                                                                                        "value",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "formValue.value",
                                                                                },
                                                                            },
                                                                            _vm._l(
                                                                              data.options,
                                                                              function (
                                                                                option
                                                                              ) {
                                                                                return _c(
                                                                                  "a-select-option",
                                                                                  {
                                                                                    key: option.id,
                                                                                    attrs:
                                                                                      {
                                                                                        value:
                                                                                          option.value,
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        option.label
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              }
                                                                            ),
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              ref:
                                                                                data.id +
                                                                                "" +
                                                                                index,
                                                                              refInFor: true,
                                                                              staticClass:
                                                                                "ant-form-explain",
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "100%",
                                                                                  color:
                                                                                    "#f5222d",
                                                                                  display:
                                                                                    "none",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                              请选择" +
                                                                                  _vm._s(
                                                                                    item.title
                                                                                  ) +
                                                                                  "\n                            "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                )
                                                              : _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "margin-top":
                                                                          "6px",
                                                                      },
                                                                  },
                                                                  _vm._l(
                                                                    data.formValues,
                                                                    function (
                                                                      formValue,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "div",
                                                                        {
                                                                          key: index,
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "a-input",
                                                                            {
                                                                              ref:
                                                                                "input" +
                                                                                data.id +
                                                                                index,
                                                                              refInFor: true,
                                                                              style:
                                                                                wrapper
                                                                                  .dataList
                                                                                  .length >
                                                                                  1 &&
                                                                                !data.value
                                                                                  ? "width:243px"
                                                                                  : wrapper
                                                                                      .dataList
                                                                                      .length >
                                                                                      1 &&
                                                                                    data.value
                                                                                  ? "width:173px"
                                                                                  : data.value
                                                                                  ? "width:430px;"
                                                                                  : "width:500px",
                                                                              attrs:
                                                                                {
                                                                                  disabled:
                                                                                    _vm.isSucDis(
                                                                                      item
                                                                                    ),
                                                                                  placeholder:
                                                                                    wrapper
                                                                                      .dataList[0]
                                                                                      .type ==
                                                                                    "INPUT"
                                                                                      ? wrapper
                                                                                          .dataList[0]
                                                                                          .description
                                                                                      : "",
                                                                                },
                                                                              on: {
                                                                                blur: (
                                                                                  e
                                                                                ) => {
                                                                                  _vm.formInputBlur(
                                                                                    e,
                                                                                    data,
                                                                                    wrapper.dataList,
                                                                                    index
                                                                                  )
                                                                                },
                                                                                change:
                                                                                  (
                                                                                    e
                                                                                  ) => {
                                                                                    _vm.formInputChange(
                                                                                      e,
                                                                                      data,
                                                                                      wrapper.dataList,
                                                                                      index
                                                                                    )
                                                                                  },
                                                                              },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    formValue.value,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        formValue,
                                                                                        "value",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "formValue.value",
                                                                                },
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              ref:
                                                                                data.id +
                                                                                "" +
                                                                                index,
                                                                              refInFor: true,
                                                                              staticClass:
                                                                                "ant-form-explain",
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "100%",
                                                                                  color:
                                                                                    "#f5222d",
                                                                                  display:
                                                                                    "none",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                              请输入" +
                                                                                  _vm._s(
                                                                                    item.title
                                                                                  ) +
                                                                                  "\n                            "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                          ]
                                                        ),
                                                      ]
                                                    }
                                                  ),
                                                  wrapper.canAdd
                                                    ? _c(
                                                        "div",
                                                        {
                                                          class:
                                                            wrapper.dataList
                                                              .length > 1
                                                              ? ""
                                                              : "only",
                                                          staticStyle: {
                                                            "min-width": "45px",
                                                            "margin-left":
                                                              "10px",
                                                            display: "flex",
                                                            "justify-content":
                                                              "space-between",
                                                            height: "100%",
                                                            "padding-top":
                                                              "16px",
                                                          },
                                                        },
                                                        [
                                                          _c("a-button", {
                                                            staticClass:
                                                              "item-btn",
                                                            attrs: {
                                                              disabled:
                                                                _vm.isSucDis(
                                                                  item
                                                                ),
                                                              shape: "circle",
                                                              size: "small",
                                                              icon: "minus",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.delItem(
                                                                  wrapper
                                                                )
                                                              },
                                                            },
                                                          }),
                                                          _c("a-button", {
                                                            staticClass:
                                                              "item-btn",
                                                            staticStyle: {
                                                              "margin-left":
                                                                "8px",
                                                            },
                                                            attrs: {
                                                              disabled:
                                                                _vm.isSucDis(
                                                                  item
                                                                ),
                                                              shape: "circle",
                                                              size: "small",
                                                              icon: "plus",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.addItem(
                                                                  wrapper
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  !wrapper.canAdd
                                                    ? _c("div", {
                                                        staticStyle: {
                                                          width: "55px",
                                                          "margin-left": "10px",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                          wrapper.type == "SELECT"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "form-select",
                                                  staticStyle: {
                                                    width: "500px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a-select",
                                                    {
                                                      ref:
                                                        "select" + wrapper.id,
                                                      refInFor: true,
                                                      attrs: {
                                                        disabled:
                                                          _vm.isSucDis(item),
                                                        allowClear: "",
                                                        showArrow: true,
                                                        mode:
                                                          wrapper.dataList[0]
                                                            .selectCount == 1
                                                            ? ""
                                                            : "multiple",
                                                        placeholder:
                                                          wrapper.dataList[0]
                                                            .description,
                                                      },
                                                      on: {
                                                        change: (e) => {
                                                          _vm.selectChange(
                                                            e,
                                                            wrapper
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          wrapper.dataList[0]
                                                            .selectValues,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            wrapper.dataList[0],
                                                            "selectValues",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "wrapper.dataList[0].selectValues",
                                                      },
                                                    },
                                                    _vm._l(
                                                      wrapper.dataList[0]
                                                        .options,
                                                      function (option) {
                                                        return _c(
                                                          "a-select-option",
                                                          {
                                                            key: option.id,
                                                            attrs: {
                                                              value:
                                                                option.value,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                attrs: {
                                                                  slot: "option",
                                                                },
                                                                slot: "option",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      option.label
                                                                    ) +
                                                                    "\n                        "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          wrapper.type == "TAG" &&
                                          !_vm.isSucDis(item)
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "form-select",
                                                  staticStyle: {
                                                    width: "500px",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        height: "40px",
                                                        display: "flex",
                                                        "align-items": "center",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          class:
                                                            wrapper.isTagShow
                                                              ? "tag-select-show"
                                                              : "tag-select",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target !==
                                                                $event.currentTarget
                                                              )
                                                                return null
                                                              return _vm.isTagShow(
                                                                wrapper
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          wrapper.dataList[0]
                                                            .formValues &&
                                                          wrapper.dataList[0]
                                                            .formValues[0].value
                                                            ? _vm._l(
                                                                wrapper
                                                                  .dataList[0]
                                                                  .formValues,
                                                                function (
                                                                  formValue
                                                                ) {
                                                                  return _c(
                                                                    "a-tag",
                                                                    {
                                                                      key: formValue.value,
                                                                      attrs: {
                                                                        closable:
                                                                          "",
                                                                      },
                                                                      on: {
                                                                        close:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.tagClose(
                                                                              formValue.value,
                                                                              wrapper
                                                                                .dataList[0]
                                                                                .formValues,
                                                                              wrapper
                                                                                .dataList[0]
                                                                                .options
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          formValue.value
                                                                        )
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "tag-select-open",
                                                              style:
                                                                wrapper.isTagShow
                                                                  ? ""
                                                                  : "height:0px;border:none",
                                                            },
                                                            [
                                                              wrapper
                                                                .dataList[0]
                                                                .formValues[0]
                                                                .value
                                                                ? _vm._l(
                                                                    wrapper
                                                                      .dataList[0]
                                                                      .options,
                                                                    function (
                                                                      option
                                                                    ) {
                                                                      return _c(
                                                                        "a-checkable-tag",
                                                                        {
                                                                          key: option.label,
                                                                          ref: option.label,
                                                                          refInFor: true,
                                                                          style:
                                                                            wrapper
                                                                              .dataList[0]
                                                                              .formValues
                                                                              .length >=
                                                                              wrapper
                                                                                .dataList[0]
                                                                                .selectCount &&
                                                                            !option.checked
                                                                              ? "pointer-events: none;color: rgba(0, 0, 0, 0.65);background: #fafafa;border: 1px solid #d9d9d9;"
                                                                              : "",
                                                                          on: {
                                                                            change:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.tagChange(
                                                                                  option.checked,
                                                                                  wrapper,
                                                                                  option
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                option.checked,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    option,
                                                                                    "checked",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "option.checked",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              option.label
                                                                            )
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  )
                                                                : _vm._l(
                                                                    wrapper
                                                                      .dataList[0]
                                                                      .options,
                                                                    function (
                                                                      option
                                                                    ) {
                                                                      return _c(
                                                                        "a-checkable-tag",
                                                                        {
                                                                          key: option.label,
                                                                          ref: option.label,
                                                                          refInFor: true,
                                                                          on: {
                                                                            change:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.tagChange(
                                                                                  option.checked,
                                                                                  wrapper,
                                                                                  option
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                option.checked,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    option,
                                                                                    "checked",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "option.checked",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              option.label
                                                                            )
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                            ],
                                                            2
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "ant-select-arrow",
                                                            },
                                                            [
                                                              _c("a-icon", {
                                                                staticStyle: {
                                                                  color:
                                                                    "rgba(0, 0, 0, 0.25)",
                                                                },
                                                                attrs: {
                                                                  type: wrapper.isTagShow
                                                                    ? "up"
                                                                    : "down",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : wrapper.type == "TAG" &&
                                              _vm.isSucDis(item)
                                            ? _c(
                                                "div",
                                                { staticClass: "dis-tag" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "ant-select-arrow",
                                                    },
                                                    [
                                                      _c("a-icon", {
                                                        staticStyle: {
                                                          color:
                                                            "rgba(0, 0, 0, 0.25)",
                                                        },
                                                        attrs: { type: "down" },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          wrapper.type == "TEXTAREA"
                                            ? _c("a-textarea", {
                                                ref: "input" + wrapper.id,
                                                refInFor: true,
                                                staticStyle: {
                                                  width: "500px",
                                                  "margin-top": "4px",
                                                },
                                                attrs: {
                                                  disabled: _vm.isSucDis(item),
                                                  "auto-size": {
                                                    minRows: 1,
                                                    maxRows: 5,
                                                  },
                                                  placeholder:
                                                    wrapper.dataList[0]
                                                      .description,
                                                },
                                                on: {
                                                  blur: (e) => {
                                                    _vm.inputBlur(e, wrapper)
                                                  },
                                                  change: (e) => {
                                                    _vm.inputChange(e, wrapper)
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    wrapper.dataList[0]
                                                      .formValues[0].value,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      wrapper.dataList[0]
                                                        .formValues[0],
                                                      "value",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "wrapper.dataList[0].formValues[0].value",
                                                },
                                              })
                                            : _vm._e(),
                                          wrapper.type == "INPUT" ||
                                          wrapper.type == "TEXTAREA"
                                            ? _c(
                                                "div",
                                                { staticClass: "zsxz" },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.getRange(
                                                          wrapper.dataList[0]
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          wrapper.type == "INPUT" ||
                                          wrapper.type == "TEXTAREA" ||
                                          wrapper.type == "INPUT_NUMBER"
                                            ? _c(
                                                "div",
                                                {
                                                  ref: wrapper.id,
                                                  refInFor: true,
                                                  staticClass:
                                                    "ant-form-explain",
                                                  staticStyle: {
                                                    color: "#f5222d",
                                                    display: "none",
                                                    "margin-left": "5px",
                                                    "align-items": "center",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    请输入整数数字\n                  "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          wrapper.type == "SELECT"
                                            ? _c(
                                                "div",
                                                {
                                                  ref: wrapper.id,
                                                  refInFor: true,
                                                  staticClass:
                                                    "ant-form-explain",
                                                  staticStyle: {
                                                    width: "100%",
                                                    color: "#f5222d",
                                                    display: "none",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    请选择" +
                                                      _vm._s(item.title) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              }),
                            ],
                            2
                          )
                        }),
                        1
                      )
                    : _c(
                        "a-form-model",
                        { staticClass: "show-form" },
                        _vm._l(_vm.formData, function (item) {
                          return _c(
                            "a-form-model-item",
                            _vm._b(
                              { key: item.id },
                              "a-form-model-item",
                              _vm.layout,
                              false
                            ),
                            [
                              item.cardList[0]
                                ? [
                                    _c(
                                      "div",
                                      {
                                        class:
                                          item.cardList[0].dataList[0]
                                            .valueRequired == "YES"
                                            ? "required"
                                            : "no-required",
                                        attrs: { slot: "label" },
                                        slot: "label",
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(item.title) +
                                            "\n                  "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "-2px",
                                            },
                                          },
                                          [_vm._v(":")]
                                        ),
                                        item.cardList[0].dataList &&
                                        item.cardList.length > 1
                                          ? _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  ref:
                                                    item.cardList[0].id +
                                                    "TEXTAREA",
                                                  refInFor: true,
                                                  staticClass:
                                                    "description-div",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.getSelectCount(
                                                          item.cardList[0]
                                                        ) +
                                                          `${
                                                            item.description
                                                              ? item.description
                                                              : ""
                                                          }`
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        item.cardList[0].dataList &&
                                        item.cardList.length == 1
                                          ? _c("div", [
                                              item.cardList[0].type !=
                                              "TEXTAREA"
                                                ? _c(
                                                    "div",
                                                    {
                                                      class:
                                                        item.cardList[0]
                                                          .dataList[0]
                                                          .formValues.length >
                                                          1 &&
                                                        item.cardList[0].type !=
                                                          "TAG"
                                                          ? "description-div"
                                                          : "description-div-one",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.getSelectCount(
                                                              item.cardList[0]
                                                            ) +
                                                              `${
                                                                item.description
                                                                  ? item.description
                                                                  : ""
                                                              }`
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      ref:
                                                        item.cardList[0].id +
                                                        "TEXTAREA",
                                                      refInFor: true,
                                                      class: _vm.getClassName(
                                                        item.cardList[0],
                                                        false
                                                      ),
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.getSelectCount(
                                                              item.cardList[0]
                                                            ) +
                                                              `${
                                                                item.description
                                                                  ? item.description
                                                                  : ""
                                                              }`
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                              _vm._l(item.cardList, function (wrapper) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      key: wrapper.id,
                                      staticStyle: { display: "flex" },
                                    },
                                    [
                                      wrapper.type == "INPUT" ||
                                      wrapper.type == "INPUT_NUMBER"
                                        ? _c("div", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  wrapper.dataList[0]
                                                    .formValues[0].value
                                                ) +
                                                "\n                  "
                                            ),
                                          ])
                                        : _vm._e(),
                                      wrapper.type == "DYNAMIC_FORM"
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "justify-content":
                                                  "space-between",
                                                "align-items": "start",
                                                width: "100%",
                                                "margin-bottom": "-12px",
                                              },
                                            },
                                            [
                                              _vm._l(
                                                wrapper.dataList,
                                                function (data, idx) {
                                                  return [
                                                    _c(
                                                      "a-form-model-item",
                                                      _vm._b(
                                                        {
                                                          key: idx,
                                                          staticClass:
                                                            "form-select",
                                                          style:
                                                            wrapper.dataList
                                                              .length > 1
                                                              ? "min-width:40%"
                                                              : "min-width:105%",
                                                        },
                                                        "a-form-model-item",
                                                        _vm.inlineLayout,
                                                        false
                                                      ),
                                                      [
                                                        data.value
                                                          ? _c(
                                                              "div",
                                                              {
                                                                attrs: {
                                                                  slot: "label",
                                                                },
                                                                slot: "label",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      data.value
                                                                    ) +
                                                                    "\n                          "
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "margin-left":
                                                                          "-2px",
                                                                      },
                                                                  },
                                                                  [_vm._v(":")]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._l(
                                                          data.formValues,
                                                          function (
                                                            formValue,
                                                            index
                                                          ) {
                                                            return [
                                                              data.type ==
                                                              "INPUT"
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      key: index,
                                                                      staticStyle:
                                                                        {
                                                                          "white-space":
                                                                            "nowrap",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            " +
                                                                          _vm._s(
                                                                            formValue.value
                                                                          ) +
                                                                          "\n                          "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "div",
                                                                    {
                                                                      key: index,
                                                                    },
                                                                    [
                                                                      formValue.value &&
                                                                      data.type ==
                                                                        "TAG"
                                                                        ? _c(
                                                                            "a-tag",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  formValue.value
                                                                                )
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      formValue.value &&
                                                                      data.type ==
                                                                        "SELECT"
                                                                        ? _c(
                                                                            "div",
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "ant-badge-status-dot ant-badge-status-processing",
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      formValue.value
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  ),
                                                            ]
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      wrapper.type == "SELECT" ||
                                      wrapper.type == "TAG"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "form-select",
                                              staticStyle: { width: "100%" },
                                            },
                                            [
                                              _vm._l(
                                                wrapper.dataList[0].formValues,
                                                function (tag, tagIndex) {
                                                  return [
                                                    tag.value &&
                                                    wrapper.type == "TAG"
                                                      ? _c(
                                                          "a-tag",
                                                          { key: tagIndex },
                                                          [
                                                            _vm._v(
                                                              _vm._s(tag.value)
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    tag.value &&
                                                    wrapper.type == "SELECT"
                                                      ? _c(
                                                          "div",
                                                          { key: tagIndex },
                                                          [
                                                            _c("span", {
                                                              staticClass:
                                                                "ant-badge-status-dot ant-badge-status-processing",
                                                            }),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  tag.value
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      wrapper.type == "TEXTAREA"
                                        ? _c(
                                            "div",
                                            {
                                              ref: "input" + wrapper.id,
                                              refInFor: true,
                                              staticStyle: {
                                                "white-space": "pre-line",
                                                "word-break": "break-word",
                                                "line-height": "1.5",
                                                "margin-top": "-12px",
                                                height: "auto",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    wrapper.dataList[0]
                                                      .formValues[0].value
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              }),
                            ],
                            2
                          )
                        }),
                        1
                      ),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("product-detail", {
                    attrs: {
                      domain: "PRODUCT_DETAIL",
                      type: "PRODUCT",
                      parent: _vm.parent,
                    },
                  }),
                ],
                1
              ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }